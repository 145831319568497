import React from "react"
import { ComposableMap, Geographies, Geography } from "react-simple-maps"

const legendStyles = {
  container: {
    borderRadius: '4px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
  },
  colorBox: {
    width: '16px',
    height: '16px',
    marginRight: '8px'
  },
  text: {
    fontSize: '14px'
  }
}

export default function MapChart() {
  const visitedCountries = new Set(["USA", "CAN", "MEX", "SLV", "PER", "TTO", "BRA", "COL", "PRY", "GBR", "FRA", "DEU", "ITA", "CHE",
    "KAZ", "IND", "KHM", "NPL", "SGP", "ARE"])
  return (
    <div className="map-container">
    <ComposableMap>
      <Geographies geography="/countries-land-10km.geo.json">
      {({ geographies }) =>
            geographies.map((geo) => {
              // Check if this country has been visited
              const isVisited = visitedCountries.has(geo.properties.A3)
              return (
                <Geography 
                  key={geo.rsmKey} 
                  geography={geo}
                  fill={isVisited ? "#4CAF50" : "#EAEAEC"}  // Green if visited, grey if not
                  stroke="#FFFFFF"
                  strokeWidth={0.5}
                  style={{
                    default: {
                      fill: isVisited ? "#4CAF50" : "#EAEAEC",
                      outline: "none"
                    },
                    hover: {
                      fill: isVisited ? "#45a049" : "#F53",
                      outline: "none"
                    },
                    pressed: {
                      fill: isVisited ? "#3d8b40" : "#E42",
                      outline: "none"
                    }
                  }}
                />
              )
            })
          }
      </Geographies>
    </ComposableMap>
        {/* Legend
    <div style={legendStyles.container}>
      <div style={legendStyles.item}>
        <div style={{ ...legendStyles.colorBox, backgroundColor: '#4CAF50' }}></div>
        <span style={legendStyles.text}>Countries I've Visited</span>
      </div>
    </div> */}
    </div>
  )
}